import Image from "next/image"
import dynamic from 'next/dynamic'
import { faArrowToBottom as falArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom'
import Link from 'next/link'
import aquiloReportImg from '../../public/assets/images/aquilo_report.jpg'

const FontAwesomeIcon = dynamic(() =>
  import('@fortawesome/react-fontawesome').then((fa) => fa.FontAwesomeIcon)
)

export default function Report() {
  return (
    <section className="report py-4 py-lg-5">
      <div className="container py-4">
        <h2 className="title">
          <small>Preview</small> Virtual wind tunnel report
        </h2>
        <div
          style={{ position: 'relative', width: '298px', height: '421px' }}
          className="my-5 mx-auto d-block shadow-lg"
        >
          <Image
            src={aquiloReportImg}
            width={298}
            height={421}
            alt="Aquilo Report"
            style={{
              maxWidth: "100%",
              height: "auto"
            }} />
        </div>
        <div className="text-center">
          <Link
            passHref
            href="https://app.airshaper.com/simulations/23067/download/report"
            className="btn btn-lg btn-outline-primary">
            <FontAwesomeIcon icon={falArrowToBottom} /> Download Sample Report
          </Link>
        </div>
      </div>
    </section>
  );
}
